import type { Dispatch, Middleware, MiddlewareAPI, Action, ActionCreator } from 'redux';
import type { DispatchWithThunkExt } from 'state';
import { makeValuesPredicate, noop } from '@sgme/fp';
import { actionNotificationStart, actionNotificationEnd } from 'utils/Timer/Timers';

export type OnActionNotifierCallback<S> = (action: any, state: S) => void;

interface IActionNotifierWhitelistMatchOrNotConfig<S> {
  didMatch: OnActionNotifierCallback<S>;
  didNotMatch: OnActionNotifierCallback<S>;
  actionsList: readonly string[];
}

interface IActionNotifierMatchAll<S> {
  didMatch: OnActionNotifierCallback<S>;
}

interface IActionNotifierWhitelistMatch<S> {
  didMatch: OnActionNotifierCallback<S>;
  actionsList: readonly string[];
}

interface IActionNotifierWhitelistNoMatch<S> {
  didNotMatch: OnActionNotifierCallback<S>;
  actionsList: readonly string[];
}

function configDefault<S>(
  config: ActionNotifierConfig<S>,
): Partial<IActionNotifierWhitelistMatchOrNotConfig<S>> {
  const conf = config as IActionNotifierWhitelistMatchOrNotConfig<S>;
  return {
    actionsList: conf.actionsList ?? undefined,
    didMatch: conf.didMatch ?? undefined,
    didNotMatch: conf.didNotMatch ?? undefined,
  };
}

export type ActionNotifierConfig<S> =
  | IActionNotifierWhitelistMatchOrNotConfig<S>
  | IActionNotifierMatchAll<S>
  | IActionNotifierWhitelistMatch<S>
  | IActionNotifierWhitelistNoMatch<S>;

export function actionNotifier<S>(config: ActionNotifierConfig<S>) {
  const { actionsList, didMatch = noop, didNotMatch = noop } = configDefault(config);
  const shouldTrigger = actionsList ? makeValuesPredicate(actionsList) : () => true;
  return ((store: MiddlewareAPI<DispatchWithThunkExt, S>) =>
    (next: Dispatch<Action>) =>
    (action: Action) => {
      actionNotificationStart();
      if (action.type === undefined) {
        actionNotificationEnd();
        return next(action);
      }
      if (shouldTrigger(action.type)) {
        didMatch(action, store.getState());
      } else {
        didNotMatch(action, store.getState());
      }
      actionNotificationEnd();
      next(action);
    }) satisfies Middleware;
}

export function actionCreatorTypeExtractor<K>(actionCreator: ActionCreator<Action<K>>): K {
  return actionCreator().type;
}
