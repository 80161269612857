import type {
  LeggedInstrument,
  FormDomain,
  ExecutableInstrument,
  HedgedInstrument,
} from './referenceData/referenceDataModel';
import type { ReferenceDataAction } from './referenceData/referenceDataActions';
import type { ClientWorkspaceAction } from './clientWorkspace/clientWorkspaceActions';
import type { GlobalErrorAction } from './globalError/globalErrorActions';
import type { FxCashAction } from './fxCashs/fxCashsActions';
import type { FxOptionAction } from './fxOptions/actions';
import type { FxSwapAction } from './fxSwaps/fxSwapsActions';
import type { FxBulkAction } from './fxBulks/fxBulksActions';
import type { FxAccumulatorAction } from './fxAccumulators/actions';
import type { FxEspStreamAction } from './esp/espStreamsActions';
import type { StreamingConnectionAction } from './streamingConnection/streamingConnectionActions';
import type { GridLayoutActions } from './gridLayout/gridLayoutActions';
import type { SessionActions } from './session/sessionActions';
import type { UserPreferencesAction } from './userPreferences/userPreferencesActions';
import type { BlotterAction, BlotterOrderAction, BlotterPanelAction } from './blotter/action';
import type { TileAction } from './tile/tileActions';
import type { FreeLayoutAction } from './freeLayout/freeLayoutActions';
import type { OrderAction } from './fxOrders/fxOrdersActions';
import type { ValueKeys, LegValueKeys, HedgeValueKeys } from './share/productModel';
import type { Mifid2PanelActions } from './mifidPanel/mifidPanelActions';
import type { ToastsActions } from './toasts/toastsActions';
import type { ValidationStatus } from 'components/Form/Inputs/typings';
import type { BidAsk } from './share/productModel/litterals';

// ██████╗  ██████╗  ██████╗ ████████╗███████╗████████╗██████╗  █████╗ ██████╗
// ██╔══██╗██╔═══██╗██╔═══██╗╚══██╔══╝██╔════╝╚══██╔══╝██╔══██╗██╔══██╗██╔══██╗
// ██████╔╝██║   ██║██║   ██║   ██║   ███████╗   ██║   ██████╔╝███████║██████╔╝
// ██╔══██╗██║   ██║██║   ██║   ██║   ╚════██║   ██║   ██╔══██╗██╔══██║██╔═══╝
// ██████╔╝╚██████╔╝╚██████╔╝   ██║   ███████║   ██║   ██║  ██║██║  ██║██║
// ╚═════╝  ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝

export interface AppBootstrappedAction {
  type: 'APP_BOOTSTRAPPED';
}

export function appBootstrapped(): AppBootstrappedAction {
  return { type: 'APP_BOOTSTRAPPED' };
}

// ██╗      ██████╗  ██████╗
// ██║     ██╔═══██╗██╔════╝
// ██║     ██║   ██║██║  ███╗
// ██║     ██║   ██║██║   ██║
// ███████╗╚██████╔╝╚██████╔╝
// ╚══════╝ ╚═════╝  ╚═════╝

export interface LogAction<T> {
  type: 'LOG_ACTION';
  message: string;
  data?: T;
}

export function logAction<T>(message: string, data?: T): LogAction<T> {
  return {
    type: 'LOG_ACTION',
    message,
    data,
  };
}

// ██╗███╗   ██╗███████╗████████╗██████╗ ██╗   ██╗███╗   ███╗███████╗███╗   ██╗████████╗    ███████╗██╗███████╗██╗     ██████╗
// ██║████╗  ██║██╔════╝╚══██╔══╝██╔══██╗██║   ██║████╗ ████║██╔════╝████╗  ██║╚══██╔══╝    ██╔════╝██║██╔════╝██║     ██╔══██╗
// ██║██╔██╗ ██║███████╗   ██║   ██████╔╝██║   ██║██╔████╔██║█████╗  ██╔██╗ ██║   ██║       █████╗  ██║█████╗  ██║     ██║  ██║
// ██║██║╚██╗██║╚════██║   ██║   ██╔══██╗██║   ██║██║╚██╔╝██║██╔══╝  ██║╚██╗██║   ██║       ██╔══╝  ██║██╔══╝  ██║     ██║  ██║
// ██║██║ ╚████║███████║   ██║   ██║  ██║╚██████╔╝██║ ╚═╝ ██║███████╗██║ ╚████║   ██║       ██║     ██║███████╗███████╗██████╔╝
// ╚═╝╚═╝  ╚═══╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝       ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝

export interface FieldTooltipSeen<I extends FormDomain> {
  type: 'FIELD_TOOLTIP_SEEN';
  instrument: I;
  field: ValueKeys<I>;
  quoteId: string;
}

export function fieldTooltipSeen<I extends FormDomain>(
  instrument: I,
  field: ValueKeys<I>,
  quoteId: string,
): FieldTooltipSeen<I> {
  return {
    type: 'FIELD_TOOLTIP_SEEN',
    instrument,
    field,
    quoteId,
  };
}

export interface LocalFieldValidationClear<I extends FormDomain> {
  type: 'LOCAL_FIELD_VALIDATION_CLEAR';
  instrument: I;
  quoteId: string;
  field: ValueKeys<I>;
}

export function localFieldValidationClear<I extends FormDomain>(
  instrument: I,
  quoteId: string,
  field: ValueKeys<I>,
): LocalFieldValidationClear<I> {
  return {
    type: 'LOCAL_FIELD_VALIDATION_CLEAR',
    instrument,
    quoteId,
    field,
  };
}

export interface LocalFieldValidationSet<I extends FormDomain> {
  type: 'LOCAL_FIELD_VALIDATION_SET';
  instrument: I;
  quoteId: string;
  field: ValueKeys<I>;
  validationStatus: ValidationStatus;
  messageId: string;
}

export function localFieldValidationSet<I extends FormDomain>(
  instrument: I,
  quoteId: string,
  field: ValueKeys<I>,
  validationStatus: ValidationStatus,
  messageId: string,
): LocalFieldValidationSet<I> {
  return {
    type: 'LOCAL_FIELD_VALIDATION_SET',
    instrument,
    quoteId,
    field,
    validationStatus,
    messageId,
  };
}

// ██╗     ███████╗ ██████╗     ███████╗██╗███████╗██╗     ██████╗
// ██║     ██╔════╝██╔════╝     ██╔════╝██║██╔════╝██║     ██╔══██╗
// ██║     █████╗  ██║  ███╗    █████╗  ██║█████╗  ██║     ██║  ██║
// ██║     ██╔══╝  ██║   ██║    ██╔══╝  ██║██╔══╝  ██║     ██║  ██║
// ███████╗███████╗╚██████╔╝    ██║     ██║███████╗███████╗██████╔╝
// ╚══════╝╚══════╝ ╚═════╝     ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝

export interface LegFieldTooltipSeen<I extends LeggedInstrument> {
  type: 'LEG_FIELD_TOOLTIP_SEEN';
  instrument: I;
  field: LegValueKeys<I>;
  quoteId: string;
  legId: string;
}

export function legFieldTooltipSeen<I extends LeggedInstrument>(
  instrument: I,
  field: LegValueKeys<I>,
  quoteId: string,
  legId: string,
): LegFieldTooltipSeen<I> {
  return {
    type: 'LEG_FIELD_TOOLTIP_SEEN',
    instrument,
    field,
    quoteId,
    legId,
  };
}

export interface LocalLegFieldValidationClear<I extends LeggedInstrument> {
  type: 'LOCAL_LEG_FIELD_VALIDATION_CLEAR';
  instrument: I;
  quoteId: string;
  legId: string;
  field: LegValueKeys<I>;
}

export function localLegFieldValidationClear<I extends LeggedInstrument>(
  instrument: I,
  quoteId: string,
  legId: string,
  field: LegValueKeys<I>,
): LocalLegFieldValidationClear<I> {
  return {
    type: 'LOCAL_LEG_FIELD_VALIDATION_CLEAR',
    instrument,
    quoteId,
    legId,
    field,
  };
}

export interface LocalLegFieldValidationSet<I extends LeggedInstrument> {
  type: 'LOCAL_LEG_FIELD_VALIDATION_SET';
  instrument: I;
  quoteId: string;
  legId: string;
  field: LegValueKeys<I>;
  validationStatus: ValidationStatus;
  messageId: string;
}

export function localLegFieldValidationSet<I extends LeggedInstrument>(
  instrument: I,
  quoteId: string,
  legId: string,
  field: LegValueKeys<I>,
  validationStatus: ValidationStatus,
  messageId: string,
): LocalLegFieldValidationSet<I> {
  return {
    type: 'LOCAL_LEG_FIELD_VALIDATION_SET',
    instrument,
    quoteId,
    legId,
    field,
    validationStatus,
    messageId,
  };
}

// ██╗  ██╗███████╗██████╗  ██████╗ ███████╗    ███████╗██╗███████╗██╗     ██████╗
// ██║  ██║██╔════╝██╔══██╗██╔════╝ ██╔════╝    ██╔════╝██║██╔════╝██║     ██╔══██╗
// ███████║█████╗  ██║  ██║██║  ███╗█████╗      █████╗  ██║█████╗  ██║     ██║  ██║
// ██╔══██║██╔══╝  ██║  ██║██║   ██║██╔══╝      ██╔══╝  ██║██╔══╝  ██║     ██║  ██║
// ██║  ██║███████╗██████╔╝╚██████╔╝███████╗    ██║     ██║███████╗███████╗██████╔╝
// ╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝ ╚══════╝    ╚═╝     ╚═╝╚══════╝╚══════╝╚═════╝

export interface HedgeFieldTooltipSeen<I extends HedgedInstrument> {
  type: 'HEDGE_FIELD_TOOLTIP_SEEN';
  instrument: I;
  field: HedgeValueKeys<I>;
  quoteId: string;
  hedgeId: string;
}

export function hedgeFieldTooltipSeen<I extends HedgedInstrument>(
  instrument: I,
  field: HedgeValueKeys<I>,
  quoteId: string,
  hedgeId: string,
): HedgeFieldTooltipSeen<I> {
  return {
    type: 'HEDGE_FIELD_TOOLTIP_SEEN',
    instrument,
    field,
    quoteId,
    hedgeId,
  };
}

export interface HedgeReset {
  type: 'HEDGE_RESET';
  quoteId: string;
}
export function hedgeReset(quoteId: string): HedgeReset {
  return {
    type: 'HEDGE_RESET',
    quoteId,
  };
}

export interface LocalHedgeFieldValidationClear<I extends HedgedInstrument> {
  type: 'LOCAL_HEDGE_FIELD_VALIDATION_CLEAR';
  instrument: I;
  quoteId: string;
  hedgeId: string;
  field: HedgeValueKeys<I>;
}

export function localHedgeFieldValidationClear<I extends HedgedInstrument>(
  instrument: I,
  quoteId: string,
  hedgeId: string,
  field: HedgeValueKeys<I>,
): LocalHedgeFieldValidationClear<I> {
  return {
    type: 'LOCAL_HEDGE_FIELD_VALIDATION_CLEAR',
    instrument,
    quoteId,
    hedgeId,
    field,
  };
}

export interface LocalHedgeFieldValidationSet<I extends HedgedInstrument> {
  type: 'LOCAL_HEDGE_FIELD_VALIDATION_SET';
  instrument: I;
  quoteId: string;
  hedgeId: string;
  field: HedgeValueKeys<I>;
  validationStatus: ValidationStatus;
  messageId: string;
}

export function localHedgeFieldValidationSet<I extends HedgedInstrument>(
  instrument: I,
  quoteId: string,
  hedgeId: string,
  field: HedgeValueKeys<I>,
  validationStatus: ValidationStatus,
  messageId: string,
): LocalHedgeFieldValidationSet<I> {
  return {
    type: 'LOCAL_HEDGE_FIELD_VALIDATION_SET',
    instrument,
    quoteId,
    hedgeId,
    field,
    validationStatus,
    messageId,
  };
}

// ██████╗  █████╗ ████████╗███████╗
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
// ██║  ██║███████║   ██║   █████╗
// ██║  ██║██╔══██║   ██║   ██╔══╝
// ██████╔╝██║  ██║   ██║   ███████╗
// ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

export interface MaxDateSet {
  type: 'MAX_DATE_SET';
  currencyPair: string;
  maxTenor: string;
  maxDate: Date;
}

export function maxDateSet(currencyPair: string, maxTenor: string, maxDate: Date): MaxDateSet {
  return {
    type: 'MAX_DATE_SET',
    currencyPair,
    maxTenor,
    maxDate,
  };
}

// ██████╗ ██████╗ ██╗ ██████╗███████╗
// ██╔══██╗██╔══██╗██║██╔════╝██╔════╝
// ██████╔╝██████╔╝██║██║     █████╗
// ██╔═══╝ ██╔══██╗██║██║     ██╔══╝
// ██║     ██║  ██║██║╚██████╗███████╗
// ╚═╝     ╚═╝  ╚═╝╚═╝ ╚═════╝╚══════╝

export type RecordPriceSucceeded = ReturnType<typeof recordPriceSucceeded>;

export function recordPriceSucceeded(
  instrument: ExecutableInstrument,
  tileId: string,
  prices: ReadonlyArray<BidAsk<number>>,
  timestamp: Date,
) {
  return {
    type: 'RECORD_PRICE_SUCCEEDED',
    instrument,
    tileId,
    prices,
    timestamp,
    error: false,
  } as const;
}

export type RecordPriceFailed = ReturnType<typeof recordPriceFailed>;

export function recordPriceFailed(
  instrument: ExecutableInstrument,
  tileId: string,
  timestamp: Date,
) {
  return {
    type: 'RECORD_PRICE_FAILED',
    instrument,
    tileId,
    timestamp,
    error: true,
  } as const;
}

export interface RecordPriceEpic {
  type: 'RECORD_PRICE_EPIC';
  tileId: string;
}

export function recordPriceEpic(tileId: string): RecordPriceEpic {
  return {
    type: 'RECORD_PRICE_EPIC',
    tileId,
  };
}

//  █████╗ ██╗     ██╗          █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██║     ██║         ██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
// ███████║██║     ██║         ███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
// ██╔══██║██║     ██║         ██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
// ██║  ██║███████╗███████╗    ██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
// ╚═╝  ╚═╝╚══════╝╚══════╝    ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
export type Action =
  | AppBootstrappedAction
  | ClientWorkspaceAction
  | ReferenceDataAction
  | GlobalErrorAction
  | FxOptionAction
  | FxCashAction
  | FxSwapAction
  | FxBulkAction
  | FxAccumulatorAction
  | FxEspStreamAction
  | TileAction
  | StreamingConnectionAction
  | GridLayoutActions
  | LogAction<any>
  | FieldTooltipSeen<FormDomain>
  | LocalFieldValidationSet<FormDomain>
  | LocalFieldValidationClear<FormDomain>
  | LegFieldTooltipSeen<LeggedInstrument>
  | LocalLegFieldValidationSet<LeggedInstrument>
  | LocalLegFieldValidationClear<LeggedInstrument>
  | HedgeFieldTooltipSeen<HedgedInstrument>
  | HedgeReset
  | LocalHedgeFieldValidationClear<HedgedInstrument>
  | LocalHedgeFieldValidationSet<HedgedInstrument>
  | MaxDateSet
  | SessionActions
  | UserPreferencesAction
  | BlotterAction
  | BlotterOrderAction
  | BlotterPanelAction
  | FreeLayoutAction
  | OrderAction
  | Mifid2PanelActions
  | ToastsActions
  | RecordPriceEpic
  | RecordPriceFailed
  | RecordPriceSucceeded;
