import { referenceDataApi } from './referenceData';
import { optionTradeCaptureApi } from './tradeCapture/option';
import { cashTradeCaptureApi } from './tradeCapture/cash';
import { swapTradeCaptureApi } from './tradeCapture/swap';
import { bulkTradeCaptureApi } from './tradeCapture/bulk';
import { accumulatorTradeCaptureApi } from './tradeCapture/accumulator';
import { quoteCancelRequestApi } from './multipass/option/rfsQuoteCancel';
import { optionQuoteRequestApi } from './multipass/option/optionQuoteRequest';
import { optionQuoteUpdateApi } from './multipass/option/optionRfsQuoteUpdate';
import type { ISgmeHttp } from 'utils/sgmeHttp';
import { optionQuoteExecuteRequestApi } from './multipass/option/optionQuoteExecution';
import { cashRfsQuoteExecuteRequestApi } from './multipass/cash/rfs/cashRfsQuoteExecution';
import { cashEspQuoteExecuteRequestApi } from './multipass/cash/esp/cashEspQuoteExecution';
import { cashRfsQuoteRequestApi } from './multipass/cash/rfs/cashRfsQuoteRequest';
import {
  cashStreamRequestApi,
  cashStreamsRefreshApi,
} from './multipass/cash/esp/cashStreamRequest';
import { espStreamUnsubscribeRequestApi } from './multipass/cash/esp/espStreamUnsubcribe';
import { omsApi } from './order/oms';
import { recordPriceRequestApi } from './multipass/recordPriceRequestApi';
import { sessionServiceApi } from './sessionService';
import { sendHelpRequestObservable } from './help';
import { swapRfsQuoteRequestApi } from './multipass/swap/swapRfsQuoteRequest';
import { swapRfsQuoteExecuteRequestApi } from 'api/multipass/swap/swapRfsQuoteExecution';
import { swapRfsCancelApi } from 'api/multipass/swap/swapRfsQuoteCancel';
import { cashRfsCancelApi } from 'api/multipass/cash/rfs/cashRfsQuoteCancel';
import { workspaceServiceApi } from './workspaceService/workspaceService';
import { userPreferencesServiceApi } from './userPreferencesService';
import { eventLogApi } from './eventLog';
import { bulkQuoteRequestApi } from './multipass/bulk/bulkQuoteRequest';
import { bulkQuoteCancelApi } from './multipass/bulk/bulkQuoteCancel';
import { bulkQuoteExecuteApi } from './multipass/bulk/bulkQuoteExecution';
import { blotterServiceApi } from './blotter/blotterService';
import { cashTieringRequestApi } from './multipass/cash/esp/cashTieringRequest';

export * from './referenceData';
export * from './tradeCapture/option';
export * from './tradeCapture/cash';
export * from './tradeCapture/swap';
export * from './multipass/option/optionQuoteRequest';
export * from './multipass/option/optionQuoteRequestBuilder';
export * from './multipass/bulk/bulkQuoteRequest';

import type { FxApi } from './typings';
import { orderEmailsApi } from './order/orderEmails';
import { xoneAutomationApi } from './xoneAutomation';
import { preTradeRegistrationApi } from './preTradeRegistrations';
import { bamSearchApi } from './bamSearch';
import { accumulatorRfsRequestApi } from './multipass/accumulator/accumulatorRfsRequest';
import { accumulatorRfsCancelApi } from './multipass/accumulator/accumulatorRfsCancel';
import { accumulatorRfsExecuteApi } from './multipass/accumulator/accumulatorRfsExecute';
import { ordersTemplateServiceApi } from './ordersTemplatesService';
import { dateApi } from './dateApi';
import { pinnedCompaniesServiceApi } from './pinnedCompaniesService';

export function createFxApi(http: ISgmeHttp): FxApi {
  return {
    orderApi: omsApi(http),
    orderEmailsApi: orderEmailsApi(http),
    referenceDataApi: referenceDataApi(http),
    blotterServiceApi: blotterServiceApi(http),
    optionTradeCaptureApi: optionTradeCaptureApi(http),
    cashTradeCaptureApi: cashTradeCaptureApi(http),
    swapTradeCaptureApi: swapTradeCaptureApi(http),
    bulkTradeCaptureApi: bulkTradeCaptureApi(http),
    accumulatorTradeCaptureApi: accumulatorTradeCaptureApi(http),
    accumulatorRfsRequestApi: accumulatorRfsRequestApi(http),
    accumulatorRfsExecuteApi: accumulatorRfsExecuteApi(http),
    accumulatorRfsCancelApi: accumulatorRfsCancelApi(http),
    optionQuoteRequestApi: optionQuoteRequestApi(http),
    optionQuoteUpdateApi: optionQuoteUpdateApi(http),
    optionRfsCancelApi: quoteCancelRequestApi(http),
    optionQuoteExecuteRequestApi: optionQuoteExecuteRequestApi(http),
    bulkQuoteRequestApi: bulkQuoteRequestApi(http),
    bulkRfsCancelApi: bulkQuoteCancelApi(http),
    cashRfsQuoteExecuteRequestApi: cashRfsQuoteExecuteRequestApi(http),
    cashEspQuoteExecuteRequestApi: cashEspQuoteExecuteRequestApi(http),
    cashRfsQuoteRequestApi: cashRfsQuoteRequestApi(http),
    cashRfsCancelApi: cashRfsCancelApi(http),
    cashTieringRequest: cashTieringRequestApi(http),
    cashStreamRequest: cashStreamRequestApi(http),
    cashStreamsRefresh: cashStreamsRefreshApi(http),
    espStreamUnsubscribeRequestApi: espStreamUnsubscribeRequestApi(http),
    swapRfsQuoteRequestApi: swapRfsQuoteRequestApi(http),
    swapRfsQuoteExecuteRequestApi: swapRfsQuoteExecuteRequestApi(http),
    swapRfsCancelApi: swapRfsCancelApi(http),
    recordPriceRequestApi: recordPriceRequestApi(http),
    sessionServiceApi: sessionServiceApi(http),
    sendHelpRequest: sendHelpRequestObservable,
    bulkQuoteExecuteRequestApi: bulkQuoteExecuteApi(http),
    workspaceServiceApi: workspaceServiceApi(http),
    orderTemplatesServiceApi: ordersTemplateServiceApi(http),
    userPreferencesServiceApi: userPreferencesServiceApi(http),
    eventLogApi: eventLogApi(http),
    xoneAutomationApi: xoneAutomationApi(http),
    preTradeRegistrations: preTradeRegistrationApi(http),
    bamSearch: bamSearchApi(http),
    dateApi: dateApi(http),
    pinnedApi: pinnedCompaniesServiceApi(http),
  };
}
