import type { AppState } from 'state/model';
import { logger } from 'logging/logger';
import {
  type OnActionNotifierCallback,
  actionCreatorTypeExtractor,
} from 'redux-middlewares/actionNotificationMiddleware';
import type { Action } from 'state/actions';
import type { AnyAction } from 'redux';
import { extract } from 'utils/object';
import { createAppCrashedAction } from 'state/globalError';
import { changeTileClientError } from 'state/clientWorkspace';

const errorActions: readonly string[] = [createAppCrashedAction, changeTileClientError].map(
  actionCreatorTypeExtractor,
);

const sanitizeLoggedAction = (action: Action): AnyAction => {
  if (action.type === 'BLOTTER_INTRADAY_TRADES_RECEIVED') {
    const { trades, ...rest } = action;
    return {
      ...rest,
      trades: trades.map(extract('id')),
    };
  }
  return action;
};

export const logActionCallback: OnActionNotifierCallback<AppState> = (action: Action) => {
  if (errorActions.includes(action.type)) {
    logger.logError('error action {action}', JSON.stringify(action));
  } else {
    logger.logInformation('action {action}', JSON.stringify(sanitizeLoggedAction(action)));
  }
};
