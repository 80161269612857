import type { Store } from 'redux';
import type { AppState } from 'state/model';
import type { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  cashExecutionReceived,
  cashExecutionFailed,
  cashFillReportReceived,
} from 'state/fxCashs/fxCashsActions';
import {
  swapExecutionReceived,
  swapExecutionFailed,
  swapFillReportReceived,
} from 'state/fxSwaps/fxSwapsActions';
import {
  optionExecutionReceived,
  optionExecutionFailed,
  optionFillReportReceived,
} from 'state/fxOptions/actions/optionProduct';
import {
  accumulatorExecutionReceived,
  accumulatorExecutionFailed,
  accumulatorFillReportReceived,
} from 'state/fxAccumulators/actions';
import {
  bulkExecutionReceived,
  bulkFillReportReceived,
  bulkExecutionFailed,
} from 'state/fxBulks/fxBulksActions';
import { assertUnhandled } from 'utils/error';
import type { IStreamError } from 'state/globalError/globalErrorModel';
import type { IStreamingData } from 'bootstrap/streams';

export function listenToAllExecution(
  { dispatch }: Store<AppState>,
  dataStream$: Observable<IStreamingData>,
) {
  dataStream$.pipe(filter(({ data: { Type } }) => Type === 'DEAL.REPLY')).subscribe(
    ({
      data: {
        Execute: { ProductType: productType, ExecutionId: executionId },
      },
    }) => {
      switch (productType) {
        case 'ForexCash':
          dispatch(cashExecutionReceived(executionId));
          break;
        case 'ForexOption':
          dispatch(optionExecutionReceived(executionId));
          break;
        case 'ForexSwap':
          dispatch(swapExecutionReceived(executionId));
          break;
        case 'ForexBulk':
          dispatch(bulkExecutionReceived(executionId));
          break;
        case 'ForexTargetAccumulator':
        case 'ForexForwardAccumulator':
          dispatch(accumulatorExecutionReceived(executionId));
          break;

        default:
          assertUnhandled('Unknown product type for DEAL.REPLY', productType);
      }
    },
  );

  dataStream$.pipe(filter(({ data: { Type } }) => Type === 'FILL.REPORT')).subscribe(
    ({
      data: {
        Execute: { ProductType: productType, ExecutionId: executionId },
        BackOfficeReply: { TradeIds: tradeIds },
      },
    }) => {
      switch (productType) {
        case 'ForexCash':
          dispatch(cashFillReportReceived(executionId, tradeIds));
          break;
        case 'ForexOption':
          dispatch(optionFillReportReceived(executionId, tradeIds));
          break;
        case 'ForexSwap':
          dispatch(swapFillReportReceived(executionId, tradeIds));
          break;
        case 'ForexBulk':
          dispatch(bulkFillReportReceived(executionId, tradeIds));
          break;
        case 'ForexTargetAccumulator':
        case 'ForexForwardAccumulator':
          dispatch(accumulatorFillReportReceived(executionId, tradeIds));
          break;
        default:
          assertUnhandled('Unknown product type for FILL.REPORT', productType);
      }
    },
  );

  dataStream$.pipe(filter(({ data: { Type } }) => Type === 'DEAL.REJECTED')).subscribe(
    ({
      data: {
        DealReject: reject,
        Execute: { ProductType: productType, ExecutionId: executionId },
      },
    }) => {
      const mappedReject = mapRejectToStreamError(reject);
      switch (productType) {
        case 'ForexCash':
          dispatch(cashExecutionFailed(executionId, mappedReject));
          break;
        case 'ForexOption':
          dispatch(optionExecutionFailed(executionId, mappedReject));
          break;
        case 'ForexSwap':
          dispatch(swapExecutionFailed(executionId, mappedReject));
          break;
        case 'ForexBulk':
          dispatch(bulkExecutionFailed(executionId, mappedReject));
          break;
        case 'ForexTargetAccumulator':
        case 'ForexForwardAccumulator':
          dispatch(accumulatorExecutionFailed(executionId, mappedReject));
          break;
        default:
          assertUnhandled('Unknown product type for DEAL.REJECTED', productType);
      }
    },
  );
}

const mapRejectToStreamError = (reject: any): IStreamError => ({
  multipassSessionId: reject.MultipassSessionId,
  multipassErrorCode: reject.MultipassErrorCode,
  code: reject.ErrorCode,
  message: reject.ErrorMessage,
});
