import * as bulkMsl from './bulk';
import * as optionMsl from './option';
import * as swapMsl from './swap';
import * as cashMsl from './cash';
import * as blotterMsl from './blotter';
import * as eventLogMsl from './eventLog';
import * as accumulatorMsl from './accumulator';
import * as orderMsl from './order';
import * as espMsl from './esp';
import * as workspaceMsl from './workspace';

export const metaSelectors = {
  ...bulkMsl,
  ...optionMsl,
  ...swapMsl,
  ...cashMsl,
  ...blotterMsl,
  ...eventLogMsl,
  ...accumulatorMsl,
  ...orderMsl,
  ...espMsl,
  ...workspaceMsl,
};
export type MetaSelectors = typeof metaSelectors;
