import {
  actionNotifier,
  actionCreatorTypeExtractor,
} from 'redux-middlewares/actionNotificationMiddleware';
import type { Action } from 'state/actions';
import {
  createAppCrashedAction,
  authExpiredAction,
  type AppCrashedAction,
} from 'state/globalError';
import { espPriceReceived } from 'state/esp/espStreamsActions';
import { notifyDisconnection } from 'state/session/sessionActions';
import {
  gridItemDragEnd,
  gridItemSizeChange,
  gridItemDrag,
} from 'state/gridLayout/gridLayoutActions';
import { logActionCallback } from './logActionCallback';
import { crash } from 'circuitBreaker';
import { tryCatchMiddleware } from 'redux-middlewares/tryCatchMiddleware';
import { crashHandler } from './crashEventHandler';
import { sendHelpRequest } from 'api/help';

export const logActionMiddleware = actionNotifier({
  didNotMatch: logActionCallback,
  actionsList: [espPriceReceived, gridItemSizeChange, gridItemDrag, gridItemDragEnd].map(
    actionCreatorTypeExtractor,
  ),
});

export const crashOnActionMiddleware = actionNotifier({
  didMatch: crash,
  actionsList: [createAppCrashedAction, authExpiredAction, notifyDisconnection].map(
    actionCreatorTypeExtractor,
  ),
});

export const autosendHelpRequestMiddleware = (isDev: boolean) => {
  const actionsList = isDev ? [] : [actionCreatorTypeExtractor(createAppCrashedAction)];
  return actionNotifier({
    didMatch: (err: AppCrashedAction) => {
      const { error } = err;
      sendHelpRequest({
        config: 'appcrashed',
        details: `Automatic help request sent after APP_CRASHED${
          typeof error === 'object' && 'message' in error && error.message !== undefined
            ? ` (${error.message})`
            : ''
        }${
          typeof error === 'object' && 'stack' in error && error.stack !== undefined
            ? ':\n' + error.stack
            : ''
        }`,
        includeLastActions: true,
        includeState: true,
        sendConfirmation: false,
      });
    },
    actionsList,
  });
};

export const catchMiddleware = tryCatchMiddleware(crashHandler);

export const rememberLastActionsMiddleware = (actionsBuffer: Action[] = [], maxActions = 50) =>
  actionNotifier({
    didMatch: action => {
      actionsBuffer.unshift(action);
      actionsBuffer.splice(maxActions);
    },
  });
