import type { Reducer } from 'redux';
import type { IWorkspaceGrowls, GrowlData } from '../clientWorkspaceModel';
import type { Action } from 'state/actions';

export const clientWorkspaceGrowlsReducer: Reducer<IWorkspaceGrowls> = (
  growls: IWorkspaceGrowls = [],
  action: Action,
): IWorkspaceGrowls => {
  switch (action.type) {
    case 'OPTION_EXECUTION_SENT':
    case 'CASH_RFS_EXECUTION_SENT':
    case 'CASH_ESP_EXECUTION_SENT':
    case 'SWAP_EXECUTION_SENT':
    case 'ACCUMULATOR_EXECUTION_SENT':
      const newGrowl: GrowlData = {
        id: action.executionId,
        instrument: action.instrument,
      };
      return [newGrowl, ...growls];
    case 'GROWL_CLOSED':
      return growls.filter(({ id }) => id !== action.growlId);
    default:
      return growls;
  }
};
